<template>
  <div class="relative h-screen">
    <div class="absolute inset-0 h-full">
      <base-video
        autoplay
        :controls="false"
        fit="cover"
        loop
        src="https://assets.vans.com/video/upload/v1738710848/404-video-optimized-red"
      />
    </div>
    <div v-style:p="{ sm: '3.5rem 1rem 0 1rem' }" class="relative c-white container lg:pt-16">
      <h1 class="pb-6 uppercase display-1">
        {{ $t.maintenance.ERR403.title }}
      </h1>
      <div class="mt-4 ws-pre-wrap lg:(mt-6 text-md) space-y-6">
        <p>
          {{ $t.maintenance.ERR403.message }}
        </p>
        <p class="fw-bold">
          {{ $t.maintenance.ERR403.nextStepsHeader }}
        </p>
        <span>
          {{ $t.maintenance.ERR403.nextStepsDetails }}
        </span>
      </div>
      <vf-button class="mt-8 b-white <md:w-full" variant="transparent" @click="clearError({ redirect: '/' })">
        {{ $t.maintenance.ERR4xx.cta }}
      </vf-button>
    </div>
  </div>
</template>

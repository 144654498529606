import type { AgentForceChatOptions } from '#types/plugin/agentForeLiveChat'

export default (options: AgentForceChatOptions) => {
  const { $feature } = useNuxtApp()
  const { targetEnv } = useRuntimeConfig().public

  const livechatBaseConfig = $feature.configLivechat

  let chatInitialized = false

  window.initChatEnv = () => {
    window.initEmbeddedMessaging()
    window.embeddedservice_bootstrap.init(
      window.miawcfg.orgId,
      window.miawcfg.deploymentName,
      window.miawcfg.eswSiteURL,
      {
        scrt2URL: window.miawcfg.scrt2URL
      }
    )
  }

  const loadCss = () => {
    const cssPath = options.cssPath
    try {
      if (!document.querySelector(`link[href="${cssPath}"]`)) {
        useHead({
          link: () => [
            {
              rel: 'stylesheet',
              type: 'text/css',
              href: cssPath,
              once: true
            }
          ],
        })
      }
    }
    catch (err) {
      console.warn('failed to load livechat css', err)
    }
  }

  const initMessaging = async () => {
    const { ...config } = livechatBaseConfig
    const isCookieAccepted = hasCookieConsent(['required', 'performanceAndAnalytics', 'functional', 'targeting'])

    if (isCookieAccepted) {
      loadCss()

      try {
        // Load main script
        const mainScript = await loadScript(config.baseUrl![targetEnv])

        if (!mainScript.loaded) throw new Error('Failed to load main miaw agentforce script')

        // Load bootstrap script
        const bootstrapScript = await loadScript(
          config.bootstrapUrl![targetEnv],
          { onload: 'initChatEnv()' }
        )

        if (!bootstrapScript.loaded) throw new Error('Failed to load miaw agentforce bootstrap script')

        // Wait for messaging ready event
        await new Promise<void>((resolve) => {
          const handleMessagingReady = () => {
            window.removeEventListener('miaw_messagingReady', handleMessagingReady)
            resolve()
          }
          window.addEventListener('miaw_messagingReady', handleMessagingReady)
        })

        const chatData = {
          type: 'miaw_enableChat',
          CurrentBotLanguage: 'en_US',
          Brand: 'VANS',
          ChatOrigin: 'en-US',
          loyaltyEnrolled: options.isLoyaltyMember.toString(),
          CustomerID: options.details.consumerId,
          customerFirstName: options.details.firstName,
          customerLastName: options.details.lastName
        }

        const customInitedEvent = new CustomEvent('miaw_enableChat', {
          detail: { data: chatData },
          bubbles: true,
          composed: true
        })
        window.parent.dispatchEvent(customInitedEvent)
        chatInitialized = true
      }

      catch (error) {
        console.error('Error initializing messaging:', error)
        chatInitialized = false
        throw error
      }
    }
  }

  initMessaging().catch((error) => {
    console.error('Failed to initialize messaging:', error)
  })

  return {
    provide: {
      chat: {
        open: () => {
          if (!chatInitialized) {
            console.warn('Cannot open miaw agentforce chat - not properly initialized')
            return
          }
          if (window.embeddedservice_bootstrap?.utilAPI)
            window.embeddedservice_bootstrap.utilAPI.launchChat()
        }
      }
    }
  }
}

import type { ChatPlugin } from '#types/plugin/chat'
import agentForceChat from '#core/integrations/agent-force-chat'
import nuanceChat from '#core/integrations/nuance-chat'

export default defineNuxtPlugin<{ chat: ChatPlugin }>(() => {
  const { enableChat } = useFeatureFlags()
  const locale = useLocale()
  const auth = useAuthStore()
  const user = useUserStore()
  const { isLoyaltyMember, details } = storeToRefs(user)
  const currentChat = ref()
  const isAvailable = ref(false)
  const wasLoggedOut = ref(false)

  if (!enableChat) {
    return {
      provide: {
        chat: {
          open: () => console.warn('Chat is disabled by feature flag'),
          available: ref(false)
        }
      }
    }
  }

  const resetChat = () => {
    if (window.embeddedservice_bootstrap) {
      window.embeddedservice_bootstrap.userVerificationAPI.clearSession(true).then(() => {
        window.embeddedservice_bootstrap.utilAPI.removeAllComponents()
      })
    }
    isAvailable.value = false
    wasLoggedOut.value = true
  }

  const userData = computed(() => {
    if (!auth.loggedIn || !details.value || typeof isLoyaltyMember.value === 'undefined')
      return null

    const profileDetails = details.value

    return {
      isLoyaltyMember: isLoyaltyMember.value,
      details: {
        consumerId: profileDetails.consumerId,
        firstName: profileDetails.firstName,
        lastName: profileDetails.lastName
      }
    }
  })

  const initializeAgentForceChat = async () => {
    const data = userData.value

    try {
      const chatScripts = document.querySelectorAll('script[src*="vfconsumercareorg2"]')
      chatScripts.forEach((script) => script.remove())
      wasLoggedOut.value = false
      currentChat.value = agentForceChat({
        ...data!,
        cssPath: '/livechat/css/LiveChatCodeCanvasVansNORA.css'
      })
      isAvailable.value = true
    }
    catch (error) {
      console.error('Failed to initialize agent force chat:', error)
      currentChat.value = null
      isAvailable.value = false
    }
  }

  const initializeNuanceChat = () => {
    try {
      currentChat.value = nuanceChat({ eventName: 'launchVANSDesktop' })
      isAvailable.value = true
    }
    catch (error) {
      console.error('Failed to initialize nuance chat:', error)
      currentChat.value = null
      isAvailable.value = false
    }
  }

  // Initialize chat only when all data is available
  const initializeChat = async () => {
    if (!['en-us'].includes(locale)) {
      currentChat.value = ['fr-ca', 'en-ca'].includes(locale) ? {} : null
      isAvailable.value = false
      return
    }

    const data = userData.value

    // For en-us: use nuance chat if logged out, agent force if logged in
    if (locale === 'en-us') {
      if (!auth.loggedIn)
        initializeNuanceChat()
      else if (data)
        await initializeAgentForceChat()
    }
  }

  // Watch login state separately to handle logout immediately
  watch(() => auth.loggedIn, (isLoggedIn, wasLoggedIn) => {
    if (wasLoggedIn && !isLoggedIn) {
      resetChat()
      // Initialize nuance chat after logout for en-us users
      if (locale === 'en-us') initializeNuanceChat()
    }
    else if (!wasLoggedIn && isLoggedIn) {
      // If user logs in, close Nuance chat if open
      if (locale === 'en-us' && window.Inq)
        window.inqFrame.Inq.FlashPeer.closeChat() // Close Nuance chat before logging in
    }
  })

  // Watch user data for initialization only when logged in
  watch(userData, async (newData) => {
    if (auth.loggedIn && newData)
      await initializeChat()
  }, { immediate: true })

  // Initial chat setup for logged out users
  if (!auth.loggedIn && locale === 'en-us')
    initializeNuanceChat()

  return {
    provide: {
      chat: {
        open: () => {
          if (!isAvailable.value) {
            console.warn('Chat not available')
            return
          }
          return currentChat.value?.provide?.chat?.open()
        },
        available: isAvailable
      }
    }
  }
})

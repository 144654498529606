<template>
  <img
    :alt="$t.loading"
    class="fixed-0 z-overlay m-a bg-white/80"
    height="137"
    src="/loader/vans.gif"
    style="border-radius: 1rem"
    width="240"
  >
</template>
